export const pDataArray = [
        {
            pId: 1,
            cId: 1,
            cName: "Pharma",
            subCId: "a",
            subCName: "Tablet",
            pName: "VEDNAC-PS",
            pDescription: `EACH FILM COATED TABLET CONTAINS \n 
            ACECLOFENAC I.P.…………………………..…100 MG \n 
            PARACETAMOL I.P …………………………..….325 MG \n 
            EXIPENTS ………………………………………...Q.S. \n 
            COLOUR : SUNSET YELLOW FCF` 
        }, {
            pId: 2,
            cId: 1,
            cName: "Pharma",
            subCId: "a",
            subCName: "Tablet",
            pName: "VEDNAC-P",
            pDescription: `
            "EACH FILM COATED TABLET CONTAINS \n
            ACECLOFENAC I.P. ………….……….….100 MG \n
            PARACETAMOL I.P. ……………………..325 MG \n
            SERRATIOPEPTIDASE I.P.………………10 MG \n
            (20,000 UNITS OF SERRATIOPEPTIDASE) \n
            EXIPENTS………………………………..Q.S. \n
            COLOUR : SUNSET YELLOW FCF"
            `
        }, {
            pId: 3,
            cId: 1,
            cName: "Pharma",
            subCId: "a",
            subCName: "Tablet",
            pName: "VEDRACE",
            pDescription: `
            EACH UNCOATED TABLET CONTAINS
            PARACETAMOL I.P. ……………………….650 MG
            EXCIPIENTS ……………….….……………. Q.S.
            `
        }, {
            pId: 4,
            cId: 1,
            cName: "Pharma",
            subCId: "a",
            subCName: "Tablet",
            pName: "VEDNIM-P",
            pDescription: `
            EACH UNCOATED TABLET CONTAINS
            NIMESULIDE BP 100 MG
            PARACETAMOL IP 325 MG
            EXCIPIENTS Q.S
            COLOUR SUNSET YELLOW FCF
            `
        }, {
            pId: 5,
            cId: 1,
            cName: "Pharma",
            subCId: "a",
            subCName: "Tablet",
            pName: "VEDICAMOL",
            pDescription: `
            EACH UNCOATED TABLET CONTAINS
            DICLOFENAC POTASSIUM BP 50 MG
            PARACETAMOL IP 325 MG
            CHLORZOXAZONE USP 250 MG
            EXCIPIENTS Q.S.
            `
        }, {
            pId: 6,
            cId: 1,
            cName: "Pharma",
            subCId: "a",
            subCName: "Tablet",
            pName: "VEDLEVO-10",
            pDescription: `
            EACH UNCOATED DISPERSIBLE TABLET CONTAINS: 
            LEVOCETIRIZINE HYDROCHLORIDE I.P…………..10 MG
            EXCIPIENTS………………………………Q.S
            `
        }, {
            pId: 7,
            cId: 1,
            cName: "Pharma",
            subCId: "a",
            subCName: "Tablet",
            pName: "VEDLEVO-M: ",
            pDescription: `
            EACH FILM COATED TABLET CONTAINS
            MONTELUKAST SODIUM I.P.
            EQ.TO MONTELUKAST….………..……..…………….10 MG
            LEVOCETIRIZINE DIHYDROCHLORIDE I.P.………...5 MG
            EXIPENTS…………………………………………..……..Q.S.
            COLOUR : TITANIUM DIOXIDE I.P
            `
        }, {
            pId: 8,
            cId: 1,
            cName: "Pharma",
            subCId: "a",
            subCName: "Tablet",
            pName: "VED-COLD",
            pDescription: `
            COMPOSITION: 
            EACH UNCOATED TABLET CONTAINS 
            PARACETAMOL IP...................................500 MG 
            CETIRIZINE DIHYDROCHLORIDE IP............5MG 
            PHENYLEPHRINE HYDROCHLORIDE IP.....5MG 
            EXIPENTS............Q.S. 
            COLOUR : TITANIUM DIOXIDE
            `
        }, {
            pId: 9,
            cId: 1,
            cName: "Pharma",
            subCId: "a",
            subCName: "Tablet",
            pName: "VED-COLD-PLUS",
            pDescription: `
            COMPOSITION: 
            EACH UNCOATED TABLET CONTAINS 
            PARACETAMOL IP......................................500 MG 
            CHLORPHENIRAMINE MELEATE IP............2MG 
            PHENYLEPHRINE HYDROCHLORIDE IP.....10MG EXIPENTS............Q.S. 
            COLOUR : TITANIUM DIOXIDE
            `
        }, {
            pId: 10,
            cId: 1,
            cName: "Pharma",
            subCId: "a",
            subCName: "Tablet",
            pName: "VEDCIN-250 mg",
            pDescription: `
            COMPOSITION:
            EACH FILM COATED TABLET CONTAINS
            AZITHROMYCIN DIHYDRATE I.P.
            EQ TO AZITHROMYCIN(ANHYDROUS).............250 MG
            EXCIPIENTS ..................................................Q.S.
            COLOUR: TITANIUM DIOXIDE I.P.
            `
        }, {
            pId: 11,
            cId: 1,
            cName: "Pharma",
            subCId: "a",
            subCName: "Tablet",
            pName: "VEDCIN-500 mg",
            pDescription: `
            COMPOSITION:
            EACH FILM COATED TABLET CONTAINS
            AZITHROMYCIN DIHYDRATE I.P.
            EQ TO AZITHROMYCIN(ANHYDROUS).............500 MG
            EXCIPIENTS ..................................................Q.S.
            COLOUR: TITANIUM DIOXIDE I.P.
            `
        }, {
            pId: 12,
            cId: 1,
            cName: "Pharma",
            subCId: "a",
            subCName: "Tablet",
            pName: "VEDFLU – 150mg",
            pDescription: `
            EACH UNCOATED TABLETS CONTAINS
            FLUCONAZOLE I.P. …...…………………150 MG
            EXCIPIENTS…………………………………Q.S.
            `
        }, {
            pId: 13,
            cId: 1,
            cName: "Pharma",
            subCId: "a",
            subCName: "Tablet",
            pName: "VEDFLU – 400mg",
            pDescription: `
            EACH UNCOATED TABLETS CONTAINS
            FLUCONAZOLE I.P. …...…………………400 MG
            EXCIPIENTS…………………………………Q.S.
            `
        }, {
            pId: 14,
            cId: 1,
            cName: "Pharma",
            subCId: "a",
            subCName: "Tablet",
            pName: "VEDKeto",
            pDescription: `
            EACH UNCOATED TABLET CONTAINS:
            KETOCONAZOLE I.P…………………………..200 MG
            EXCIPIENTS……………………………………Q.S
            `
        }, {
            pId: 15,
            cId: 1,
            cName: "Pharma",
            subCId: "a",
            subCName: "Tablet",
            pName: "VEDPANTO-40 mg ",
            pDescription: `
            EACH ENTERIC COATED TABLET CONTAINS
            PANTOPRAZOLE SODIUM I.P.
            EQ.TO PANTOPRAZOLE …………………40 MG
            EXCIPIENTS …………………………………Q.S.
            COLOUR: YELLOW OXIDE OF IRON
            `
        }, {
            pId: 16,
            cId: 1,
            cName: "Pharma",
            subCId: "a",
            subCName: "Tablet",
            pName: "VEDPANTO-D",
            pDescription: `
            EACH ENTERIC COATED TABLET CONTAINS
            PANTOPRAZOLE SODIUM SESQUIHYDRATE I.P.
            EQ.TO PANTOPRAZOLE ……………….…………….40 MG
            DOMPERIDONE I.P. ……………………………………10 MG
            EXIPENTS………………………………………………..Q.S.
            COLOUR : TARTRAZINE
            `
        }, {
            pId: 17,
            cId: 1,
            cName: "Pharma",
            subCId: "a",
            subCName: "Tablet",
            pName: "VEDRABE-20 mg ",
            pDescription: `
            EACH ENTERIC COATED TABLET CONTAINS
            RABEPRAZOLE SODIUM I.P. …………….……20 MG
            EXCIPIENTS ……………………………………. Q.S.
            COLOUR: SUNSET YELLOW FCF
            `
        }, {
            pId: 18,
            cId: 1,
            cName: "Pharma",
            subCId: "a",
            subCName: "Tablet",
            pName: "VEDRABE-D",
            pDescription: `
            EACH ENTERIC COATED TABLET CONTAINS.
            RABEPRAZOLE SODIUM I.P. ….………..……….20 MG
            DOMPERIDONE I.P. ………………………………10 MG
            EXIPENTS…………………………………………..Q.S
            `
        }, {
            pId: 19,
            cId: 1,
            cName: "Pharma",
            subCId: "b",
            subCName: "Capsule",
            pName: "VEDOM",
            pDescription: `
            EACH HARD GELATIN CAPSULES CONTAINS IP
            OMEPRAZOLE I.P. ……………………………. 20 MG
            (AS ENTERIC COATED PELLETS)
            EXCIPIENTS………………………………………Q.S.
            COLOUR:  APPROVED COLOUR  
            `
        }, {
            pId: 20,
            cId: 1,
            cName: "Pharma",
            subCId: "b",
            subCName: "Capsule",
            pName: "VEDOM-D",
            pDescription: `
            EACH HARD GELATIN CAPSULES CONTAINS
            OMEPRAZOLE I.P. ……………………………. 20 MG
            (AS ENTERIC COATED PELLETS)
            DOMPERIDONE I.P. ……………………………10 MG
            (AS SUSTAINED RELEASE PELLETS)
            EXIPENTS…………………………………………..Q.S.
            APPROVED COLOURS (ERYTHROSINE) & 
            USED IN EMPTY  CAPSULES SHELLS
            `
        }, {
            pId: 21,
            cId: 1,
            cName: "Pharma",
            subCId: "b",
            subCName: "Capsule",
            pName: "VEDPANTO-DSR ",
            pDescription: `
            EACH HARD GELATIN CAPSULE CONTAINS
            PENTOPRAZOLE SODIUM IP
            EQ TO.PANTOPRAZOLE ………………………..40 MG
            (AS ENTERIC COATED PELLETS)
            DOMPERIDONE ………………………………30 MG 
            (AS SUSTAINED RELEASE PALLETS)
            EXCIPIENTS…………………………………….Q.S
            `
        }, {
            pId: 22,
            cId: 1,
            cName: "Pharma",
            subCId: "b",
            subCName: "Capsule",
            pName: "VEDRABE-DSR",
            pDescription: `
            EACH HARD GELATIN CAPSULE CONTAINS
            RABEPRAZOLE SODIUM ………………………………….20 MG
            (AS ENTRIC COATED PELLETS)
            DOMPERIDONE ……………………………………………30 MG
            (AS SUSTAINED RELEASE PELLETS)
            EXCIPIENTS………………………………………Q.S.
            `
        }, {
            pId: 23,
            cId: 1,
            cName: "Pharma",
            subCId: "c",
            subCName: "External",
            pName: "VED-VEDNAMUKTI",
            pDescription: `
            DICLOFENAC SODIUM 1% W/W
            LINSEED OIL BP 3% W/W
            METHYL SALICYLATE IP 10% W/W
            MENTHOL IP 5% W/W
            CAPSAICIN USP 0.025% W/W
            GEL BASE QS
            `
        }, {
            pId: 24,
            cId: 1,
            cName: "Pharma",
            subCId: "c",
            subCName: "External",
            pName: "VED-MUHVRAN",
            pDescription: `
            Choline Salicylate solution B.P. 
            eq. to Choline Salicylate - 8.7%w/w
            Lignocaine HCL I.P. - 2.0%w/w
            Benzalkonium Chloride Solution I.P. - 0.01%w/w
            pleasantly flavoured gel base Q.S.
            `
        }, {
            pId: 25,
            cId: 1,
            cName: "Pharma",
            subCId: "c",
            subCName: "External",
            pName: "VED-JIWANUNASHAK",
            pDescription: `
            Povidine Iodine IP .....5% w/w
            Ornidazole IP .....1% w/w
            Ointment Base .......Q.S.
            `
        }, {
            pId: 26,
            cId: 1,
            cName: "Pharma",
            subCId: "c",
            subCName: "External",
            pName: "VED-CLOTRI",
            pDescription: `
            Composition:
            CLOTRIMAZOLE IP 1% W/W
            CREAM BASED Q.S
            `
        }, {
            pId: 27,
            cId: 1,
            cName: "Pharma",
            subCId: "c",
            subCName: "External",
            pName: "VED-LULIZOLE",
            pDescription: `
            LULICONAZOLE............. 1% W/W
            BENZYL ALCOHOL IP.......1% W/W
            (AS PRESERVATIVE)
            CREAM BASE............... QS
            `
        }, {
            pId: 28,
            cId: 1,
            cName: "Pharma",
            subCId: "c",
            subCName: "External",
            pName: "VEDCLO-GM",
            pDescription: `
            CLOBETASOL PROPIONATE IP .......0.05% W/W
            NEOMYCIN SULPHATE IP 
            EQ.TO NEOMYCIN BASE...............0.5% W/W
            CLOTRIMAZOLE IP ...............1.00% W/W
            CHLOROCRESOL (AS PRESERVATIVE) IP..... 0.1% W/W
            CREAM BASE.......................................Q.S
            `
        }, {
            pId: 29,
            cId: 1,
            cName: "Pharma",
            subCId: "c",
            subCName: "External",
            pName: "VEDSCARCLEAN",
            pDescription: `
            COMPOSIOTION :
            HYDROQUINONE IP.............................2.00%W/W
            TRETINOIN USP.....................................0.025W/W
            MOMETASONE  FUORATE IP.................0.100W/W
            IN A CREAM BASE................................Q.S.
            `
        }, {
            pId: 30,
            cId: 1,
            cName: "Pharma",
            subCId: "c",
            subCName: "External",
            pName: "VEDPIMPWELL",
            pDescription: `
            Hydroxy Benzoyl Peroxide IP
            Eq to  Anhydrous Benzoyl Peroxide: 2.5% w/w
            `
        }, {
            pId: 31,
            cId: 1,
            cName: "Pharma",
            subCId: "c",
            subCName: "External",
            pName: "VEDPERMI",
            pDescription: `
            PERMITHRIN LOTION………………………………..5% W/V
            LOTION BASE………………………………………….Q.S
            `
        }, {
            pId: 32,
            cId: 1,
            cName: "Pharma",
            subCId: "c",
            subCName: "External",
            pName: "VEDLULIZOLE-L",
            pDescription: `
            LULICONAZOLE ……………………………….1.00%W/V
            LOTION BASE  …………………………………Q.S.
            `
        }, {
            pId: 33,
            cId: 1,
            cName: "Pharma",
            subCId: "c",
            subCName: "External",
            pName: "GUMVED PAINT",
            pDescription: `
            BECLOMETHASONE DIPROPIONATE I.P…………….0.025%W/W
            CLOTRIMAZOLE I.P……………………………………..1%W/W
            LOTION BASE…………………………………………….Q.S.
            `
        }, {
            pId: 34,
            cId: 1,
            cName: "Pharma",
            subCId: "c",
            subCName: "External",
            pName: "VED-SWASSUDHI",
            pDescription: `
            CHLORHEXIDINE GLUCONATE SOLUTION IP 
            EQ TO CHLORHEXIDINE GLUCONATE 0.2% W/V
            SODIUM FLUORIDE IP........................0.05%W/V
            ZINC CHLORIDE.................................0.09%W/V
            IN A PLESANTLY FLAVOURED AQUEOUS BASE QS
            COLOUR: PONCEU 4R
            `
        }, {
            pId: 35,
            cId: 1,
            cName: "Pharma",
            subCId: "c",
            subCName: "External",
            pName: "VEDNASICURE-X",
            pDescription: `
            XYLOMETAZOLINE HCL I.P………………………0.05%W/V
            BENZALKONIUM CHLORIDE SOLUTION………0.02%W/V
            (AS PRESERVATIVE)
            STERILE AQUEOUS BASE………………………..Q.S
            `
        }, {
            pId: 36,
            cId: 1,
            cName: "Pharma",
            subCId: "c",
            subCName: "External",
            pName: "VEDNASICURE-S",
            pDescription: `
            SODIUM CHLORIDE   I.P…………………………….0.65%W/V
            BENZALKONIUM CHLORIDE SOLUTION…………0.03%W/V
            (AS PRESERVATIVE)
            STERILE AQUEOUS BASE………………………….Q.S.
            `
        }, {
            pId: 37,
            cId: 1,
            cName: "Pharma",
            subCId: "c",
            subCName: "External",
            pName: "VEDEARWELL",
            pDescription: `
            CHLORBUTOL IP ……………………………………5.00%W/V
            PARADICHLOROBENZENE ……………….……… 2.00%W/V
            BENZOCAIN  IP………………………………………2.70%W/V
            TURPENTINE OIL BP …………………………….. 15.0%W/V
            OILY BASE ………………………………………….. Q.S
            `
        }, {
            pId: 38,
            cId: 2,
            cName: "Cosmetic",
            subCId: "d",
            subCName: "Soap",
            pName: "Ved Tulsi",
            pDescription: `  `
        }, {
            pId: 39,
            cId: 2,
            cName: "Cosmetic",
            subCId: "d",
            subCName: "Soap",
            pName: "Ved Alovera",
            pDescription: `  `
        }, {
            pId: 40,
            cId: 2,
            cName: "Cosmetic",
            subCId: "d",
            subCName: "Soap",
            pName: "Ved Neem",
            pDescription: `  `
        }, {
            pId: 41,
            cId: 2,
            cName: "Cosmetic",
            subCId: "d",
            subCName: "Soap",
            pName: "Ved Charcol",
            pDescription: `  `
        }, {
            pId: 42,
            cId: 2,
            cName: "Cosmetic",
            subCId: "e",
            subCName: "Lotion",
            pName: "VEDAH (Almond & Honey)",
            pDescription: `  `
        }, {
            pId: 43,
            cId: 2,
            cName: "Cosmetic",
            subCId: "e",
            subCName: "Lotion",
            pName: "VEDAMIN (Alovera & Vitamin E)",
            pDescription: `  `
        }, {
            pId: 44,
            cId: 3,
            cName: "Nutra",
            subCId: "f",
            subCName: "Energy Drink",
            pName: "Ved energysip – Orange Flavour",
            pDescription: `  `
        }, {
            pId: 45,
            cId: 3,
            cName: "Nutra",
            subCId: "f",
            subCName: "Energy Drink",
            pName: "Ved energysip –  Guvava Flavour",
            pDescription: `  `
        }, {
            pId: 46,
            cId: 3,
            cName: "Nutra",
            subCId: "f",
            subCName: "Energy Drink",
            pName: "Ved energysip – Lemon Flavour",
            pDescription: `  `
        }, {
            pId: 47,
            cId: 3,
            cName: "Nutra",
            subCId: "g",
            subCName: "Protein Powder",
            pName: "Vedproteins - Choclate Flavour",
            pDescription: `  `
        }, {
            pId: 48,
            cId: 3,
            cName: "Nutra",
            subCId: "g",
            subCName: "Protein Powder",
            pName: "Vedproteins - Kesar Kulfi Flavour",
            pDescription: `  `
        }
    ]


// 1. TABLET, CAPSULE & SYRUP
// 2. Gel, Ointment, Cream & Lotion
// 3. Dental Products 
// 4. Ear & Nasal Drops 
// 5. Nutraceuticals
// 6. Cosmetics 